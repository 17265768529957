var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Portlet',{staticClass:"peaksolution",attrs:{"title":"Peaksolution","icon":"mountain"}},[_c('div',{staticClass:"peakLoginDataList"},[(_vm.loading)?_c('LoadingPlaceholder'):[_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.peakLoginDatas),"columns":_vm.kgm_responsiveColumns(),"filterable":true,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.peakLoginDatas),"sortable":{
          allowUnsort: _vm.kgm_allowUnsort,
          mode: _vm.kgm_sortMode
        },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"passwordTemplate",fn:function(ref){
        var props = ref.props;
return [_c('td',{class:props.className},[_c('PasswordViewer',{attrs:{"id":props.dataItem.id,"password":'tempPw',"peak-id":props.dataItem.id,"installation-id":props.dataItem.installationId}})],1)]}},{key:"backendTemplate",fn:function(ref){
        var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem.backendUrl)?_c('a',{staticClass:"alt-primary-color",attrs:{"href":props.dataItem.backendUrl,"target":"_blank"}},[_vm._v(" Peak Backend ")]):_vm._e(),(props.dataItem.peakVersion)?[_c('br'),_c('p',[_vm._v(_vm._s(_vm.getVersionNumber(props.dataItem.peakVersion)))])]:_vm._e()],2)]}},{key:"jamesTemplate",fn:function(ref){
        var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem.frontendJamesUrl)?_c('a',{staticClass:"alt-primary-color",attrs:{"href":props.dataItem.frontendJamesUrl,"target":"_blank"}},[_vm._v(" James Frontend ")]):_vm._e(),(props.dataItem.jamesVersion)?[_c('br'),_c('span',[_vm._v(_vm._s(props.dataItem.jamesVersion))])]:_vm._e(),(props.dataItem.mwVersion)?[_c('br'),_c('span',[_vm._v("MDLW "+_vm._s(props.dataItem.mwVersion))])]:_vm._e()],2)]}},{key:"peakTemplate",fn:function(ref){
        var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem.frontendPeakUrl)?_c('a',{staticClass:"alt-primary-color",attrs:{"href":props.dataItem.frontendPeakUrl,"target":"_blank"}},[_vm._v(" Peak Frontend ")]):_vm._e()])]}},{key:"installationTemplate",fn:function(ref){
        var props = ref.props;
return [_c('td',{class:props.className},[_c('router-link',{staticClass:"alt-primary-color",attrs:{"to":("/installation/" + (props.dataItem.installationId) + "/software-components/" + (props.dataItem.componentId))}},[_vm._v(" "+_vm._s(_vm.kgm_getNestedValue(props.field, props.dataItem))+" ")])],1)]}},{key:"customerFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"customerFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Customer"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('customerFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"abbFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"abbFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a abbreviation"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('abbFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"installationFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"installationFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Installation"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('installationFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"typeFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('select',{key:("typeSelect-" + (props.value)),ref:"typeSelect",staticClass:"form-control mr-2 rounded-right alt-pointer",domProps:{"value":props.value},on:{"change":function (event) {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('pleaseSelectOne'))+" ")]),_vm._l((_vm.typeList),function(typek,key){return _c('option',{key:("typeListFilterOption-" + key),domProps:{"selected":_vm.kgm_selectDefaultOption(props, typek, 'typeSelect')}},[_vm._v(" "+_vm._s(typek)+" ")])})],2),(_vm.$refs != null && _vm.$refs.typeSelect != null && (_vm.$refs.typeSelect.value != null && _vm.$refs.typeSelect.value.length != 0))?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetSelect('typeSelect', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"usernameFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"usernameFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Username"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('usernameFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"optionsTemplate",fn:function(ref){
        var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem.hasLoginButton && props.dataItem.type == 'Application')?_c('button',{staticClass:"btn btn-success btn-sm",on:{"click":function($event){return _vm.login(props.dataItem)}}},[_c('span',[_vm._v("Login")])]):_vm._e(),_c('button',{staticClass:"btn btn-light btn-sm",attrs:{"title":"Changelog"},on:{"click":function($event){return _vm.changelog(props.dataItem)}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"file-contract"}})],1)])]}}])})]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }